import React from 'react'
import {ArtULStyle} from  './artUL.module.css'

const ArtUL = ({children}) => {
    return (
        <ul className={ArtULStyle}>
            {children}
        </ul>
    )
}

export default ArtUL
