import React from 'react'
import {ArtLIStyle} from  './artLI.module.css'

const ArtLI = ({children}) => {
    return (
        <li className={ArtLIStyle}>
            {children}
        </li>
    )
}

export default ArtLI
