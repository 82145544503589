import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import ArtLI from '../../components/Blocks/ArtLI/artLI';
import ArtUL from '../../components/Blocks/ArtUL/artUL';
import H3 from '../../components/Blocks/H3/h3';
import HeroSection from '../../components/Blocks/HeroSection/heroSection';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import FullscreenPhotoSection from '../../components/FullscreenPhotoSection/fullscreenPhotoSection';
import Layout from '../../components/layout';
import SEO from '../../components/SEO/seo';

const SnobOnboarding = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );
  return (
    <Layout >
        <SEO lang="en" title='How to improve onboarding experience and feature adoption?' keywords={['design', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'polytech']} description="UX/UI Design for Polytechnic museum of Moscow">
        </SEO>

        <HeroSection animationType="amazingliquid" heading="How to improve onboarding experience and feature adoption?" desc="Creating a onboarding flow for users to improve adoption of new features and overall onboarding for new users."/>

        <Section heading="Problem">
            <Paragraph>We updating Snob's blog part of the website. Blog's pages are obsolete, mostly editor design. And the overall visual design is not compatible with the design of the main page.</Paragraph>
            <Paragraph>Our users are mostly seniors and not necessarily tech-savvy. They may not make out the new design. We were afraid of user drain, because of new features.</Paragraph>
        </Section>

        <Section heading="Finding a solution">
            <Paragraph>We understand that we should make users familiar with the new website. So, we need a great onboarding, that explains new features</Paragraph>
            <Paragraph>We started to find a solution. First, that came to our minds it's made it by ourselves. But it's expensive and if we want to create a flexible solution, that anyone, even not coders, can edit its time and money expensive. </Paragraph>
        </Section>

        <Section heading="What we want from onboarding solution">
            <ArtUL>
              <ArtLI>Onboarding of more than 200 users;</ArtLI>
              <ArtLI>Product tours;</ArtLI>
              <ArtLI>Checklists with gamification;</ArtLI>
              <ArtLI>Responsive (looks good both on mobile and desktop)</ArtLI>
              <ArtLI>Design customization</ArtLI>
              <ArtLI>No service branding</ArtLI>
              <ArtLI>Edit without coding</ArtLI>
              <ArtLI>Onboarding analytics</ArtLI>
              <ArtLI>Can be integrated painlessly;</ArtLI>
              <ArtLI>Be cool if has a support chat (integrated intercom)</ArtLI>
            </ArtUL>
            <Paragraph>And we made a list of possible solutions-services.</Paragraph>
        </Section>

        <Section heading="Variants" >
            <H3>1) 🏆 HelpHero</H3>
            <Paragraph>Pros</Paragraph>
            <ArtUL>
              <ArtLI>At its price it is a great solution;</ArtLI>
              <ArtLI>No pricing plans. Just pay and use all the features</ArtLI>
              <ArtLI>Good documentation</ArtLI>
              <ArtLI>Developer-friendly.</ArtLI>
            </ArtUL>
            <Paragraph>Cons</Paragraph>
            <ArtUL>
              <ArtLI>Not the best editor</ArtLI>
              <ArtLI>Markdown editing. Not everyone knows how to use markdown.</ArtLI>
              <ArtLI>Need to host photos and videos.</ArtLI>
            </ArtUL>

            <H3>2) Usetiful</H3>
            <Paragraph>Pros</Paragraph>
            <ArtUL>
              <ArtLI>All the features that we need</ArtLI>
              <ArtLI>Convenient editor</ArtLI>
            </ArtUL>
            <Paragraph>Cons</Paragraph>
            <ArtUL>
              <ArtLI>The product is new and quite buggy.</ArtLI>
              <ArtLI>Strange pricing</ArtLI>
            </ArtUL>

            <H3>3) Expertto</H3>
            <Paragraph>Pros</Paragraph>
            <ArtUL>
              <ArtLI>Service localization</ArtLI>
              <ArtLI>All the features</ArtLI>
            </ArtUL>
            <Paragraph>Cons</Paragraph>
            <ArtUL>
              <ArtLI>Bad editor</ArtLI>
            </ArtUL>

            <H3>4) Intro.js</H3>
            <Paragraph>Pros</Paragraph>
            <ArtUL>
              <ArtLI>Cheap, pay only once.</ArtLI>
            </ArtUL>
            <Paragraph>Cons</Paragraph>
            <ArtUL>
              <ArtLI>It's more javascript library than service.</ArtLI>
              <ArtLI>Need to improve the editor manually, so not so cheap.</ArtLI>
            </ArtUL>

            <H3>4) Intercom Product Tours</H3>
            <Paragraph>Pros</Paragraph>
            <ArtUL>
              <ArtLI>No checklist and other features.</ArtLI>
            </ArtUL>
            <Paragraph>Cons</Paragraph>
            <ArtUL>
              <ArtLI>We do not use the Intercom products, so it's not our case.</ArtLI>
            </ArtUL>

        </Section>

        <Section heading="Making a scenarios">
            <Paragraph>So, we choose a HelpHero, it's a great solution for us.</Paragraph>
            <Paragraph>After that, we wrote down the main scenarios. What we need to show our users.</Paragraph>
            <Paragraph>First of all, it's an editor (it's more complex and unusual for users)</Paragraph>
            <Paragraph>So, on an editor page we have to notify users on:</Paragraph>
            <ArtUL>
              <ArtLI>Text formating</ArtLI>
              <ArtLI>Add new blocks (photos, links)</ArtLI>
              <ArtLI>How to publish an article, where they can find drafts. And how to edit them again.</ArtLI>
              <ArtLI>Tell them that their stories are saving automatically</ArtLI>
            </ArtUL>
            <Paragraph>And profile page:</Paragraph>
            <ArtUL>
              <ArtLI>How to edit an about information.</ArtLI>
            </ArtUL>
            <Paragraph>Then we move these scenarios to Helphero. We make basic hints for users. One important point that we shouldn't interrupt users from their flow. It's only a hint, it's not necessary.</Paragraph>
            <Paragraph>Then we started testing our solution. How it will improve their flow and most importantly for us it's the adoption of the new features. </Paragraph>
        </Section>

        <Section heading="Results">
            <Paragraph>We made an onboarding that helps us to improve the adoption rate of our new editor and other updated pages.</Paragraph>
        </Section>

    </Layout>
  ); };

export default SnobOnboarding;
